<template>
  <div>
    <div class="UserInfo_hbody">
      <div class="yyt_name">
        <span>{{mapData.yyt}}</span>
      </div>
      <div class="tel">
        <span>电话:</span>
        <span>{{mapData.fwdh}}</span>
        <van-icon name="phone" color="rgb(61, 96, 239)" />
      </div>
      <div class="open_time">
        <span>营业时间：</span>
        <span>{{mapData.yysj}}</span>
      </div>
      <div class="addr">
        <span>地址：</span>
        <span>{{mapData.dzText}}</span>
      </div>
      <div class="yyt_fwbz">
        <span>服务备注：</span>
        <span>{{mapData.mapData}}</span>
      </div>
      <!-- <div class="map-box">
        <div id="container"></div>
        <div style="display:none">
          <MapInfoWindow ref="infowindow" :address="mapData.dzText"></MapInfoWindow>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import MapInfoWindow from './MapInfoWindow.vue'
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  data() {
    return {
      map: null,
    }
  },
  props: ['mapData'],
  components: {
    MapInfoWindow,
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '69a1c635889ed5afe61ec4495e44590d', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          let lng = this.mapData.dzLng
          let lat = this.mapData.dzLat
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 12, //初始化地图级别
            center: [lng, lat], //初始化地图中心点位置
          })
          // 创建 infoWindow 实例
          var infoWindow = new AMap.InfoWindow({
            content: this.$refs.infowindow.$el, //传入 dom 对象，或者 html 字符串
            autoMove: true,
          })
          // 打开信息窗体
          infoWindow.open(this.map, [113.32756895184514, 23.130570077745276])
          var marker = new AMap.Marker({
            position: new AMap.LngLat(113.32756895184514, 23.130570077745276), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: this.mapData.dzText,
          })
          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker)
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>


<style lang="less" scpoed>
#container {
  width: 100%;
  height: 200px;
  background-color: black;
}
.UserInfo_hbody {
  text-align: left;
  padding: 15px;
  .yyt_name {
    span {
      display: inline-block;
      color: rgb(0, 0, 0);
      font-weight: 700;
      font-size: 22px;
      padding: 0 5px 15px 0;
    }
  }
  .tel {
    span:first-of-type {
      padding-right: 8px;
    }
    span:last-of-type {
      color: rgb(0, 141, 255);
      font-size: 16px;
    }
    .van-icon {
      padding: 0 5px;
    }
  }
  .open_time {
    padding: 8px 8px 8px 0;
  }
  .addr {
    padding-right: 8px;
  }
  .yyt_fwbz {
    padding-top: 10px;
  }
}
.map-box {
  margin-top: 10px;
}
</style>
<template>
  <div class="contentSer top-padding">
    <top-home title="营业网点" :className="['top-white']"></top-home>
    <div class="title_pic">
      <div class="ServicePhone">
        <h2>营业网点</h2>
        <span>- 欢迎咨询您身边的营业网点 -</span>
      </div>
    </div>
    <div class="Index_UserInfo" v-if="yytData.length<=0">
      <div>
        <img src="../../assets/PersonalCenter/PointsCenter/noDataPng.png" alt width="100%" />
        <p>暂无数据</p>
      </div>
    </div>
    <div class="yyt_info" v-else>
      <div v-for="(item,index) in yytData" :key="index" class="yyt_info_box">
        <div class="Index_UserInfo_hbody" @click="openMapFun(item)">
          <div class="UserInfo_hbody">
            <div class="yyt_name">
              <!-- <span>{{data.yytbh}}</span> -->
              <span>{{item.yyt}}</span>
            </div>
            <div class="tel">
              <span>电话:</span>
              <span>{{item.fwdh}}</span>
              <van-icon name="phone" color="rgb(61, 96, 239)" />
            </div>
            <div class="open_time">
              <span>营业时间：</span>
              <span>{{item.yysj}}</span>
            </div>
            <div class="addr">
              <span>地址：</span>
              <span>{{item.dzText}}</span>
            </div>
            <!-- <div class="yyt_fwbz">
              <span>服务备注：</span>
              <span>{{item.fwbz}}</span>
            </div>-->
          </div>
          <!-- <van-icon name="arrow" size="22px" color="rgb(177, 177, 177)" @click="junpServicePID" /> -->
        </div>
      </div>
    </div>
    <van-popup v-model="isXy" position="right" :style="{ width:'90%', height: '100%' }">
      <service-phone-map :mapData="mapData" v-if="isXy"></service-phone-map>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getWxGetYytByAppId } from '../../reuqest/api'
import ServicePhoneMap from './ServicePhoneMap.vue'
export default {
  components: {
    ServicePhoneMap,
  },
  data() {
    return {
      yytData: [],
      isXy: false,
      mapData: {},
    }
  },
  computed: {
    ...mapGetters(['appId']),
  },
  mounted() {
    this.init()
  },

  methods: {
    init() {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      console.log('cs=====', this.appId)
      getWxGetYytByAppId({
        appId: this.appId,
      }).then((res) => {
        res.data = res.data.map((item, index) => {
          if (item.yytdz) {
            let dz = item.yytdz.split(',')
            if (dz.length == 3) {
              item.dzText = dz[2]
              item.dzLng = parseFloat(dz[0])
              item.dzLat = parseFloat(dz[1])
            }
          }
          return item
        })
        this.yytData = res.data
        loadingToast.clear()
      })
    },
    junpServicePID() {
      this.$router.push('/ServicePhoneIndexDetail')
    },
    openMapFun(item) {
      this.mapData = item
      this.isXy = true
    },
  },
}
</script>
 
<style lang="less" scoped>
.contentSer {
  min-height: 100vh;
  overflow-y: auto;
  background-color: rgb(245, 245, 245);
}
* {
  margin: 0;
  padding: 0;
}
.title_pic {
  height: 150px;
  background: url('../../assets/Notice/PhoneList.jpg');
  background-size: 100% 100%;
  .ServicePhone {
    color: #fff;
    padding-top: 45px;
    text-align: center;
  }
}
.Index_UserInfo {
  width: 94%;
  background: #fff;
  margin: 0 auto;
  margin-top: 30px;
  border-radius: 7px;
  padding-bottom: 12px;
}
.yyt_info {
  .yyt_info_box {
    background-color: #fff;
    padding: 8px;
    margin: 10px;
    border: 1px solid rgb(245, 245, 245);
    border-radius: 10px;
    box-shadow: #ccc;
  }
}
.Index_UserInfo_hbody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .UserInfo_hbody {
    text-align: left;
    padding: 10px;
    .yyt_name {
      span {
        display: inline-block;
        color: rgb(0, 0, 0);
        font-weight: 700;
        font-size: 22px;
        padding: 0 5px 15px 0;
      }
    }
    .tel {
      span:first-of-type {
        padding-right: 8px;
      }
      span:last-of-type {
        color: rgb(0, 141, 255);
        font-size: 16px;
      }
      .van-icon {
        padding: 0 5px;
      }
    }
    .open_time {
      padding: 8px 8px 8px 0;
    }
    .addr {
      padding-right: 8px;
    }
  }
}
#map {
  width: 100%;
  height: 200px;
  background-color: black;
}
.yyt_fwbz {
  padding-top: 5px;
}
// .yyt_fwbz {
//   text-align: left;
//   font-size: 14px;
//   line-height: 25px;
//   padding-top: 10px;
//   span {
//     font-size: 16px;
//   }
// }
</style>
<template>
  <div class="contentsB top-padding">
    <top-home :title="$route.query.type=='ywbl'?'业务办理':'维保信息'"></top-home>
    <div v-if="$route.query.type=='ywbl'">
      <van-cell
        value
        is-link
        @click="$router.push('/BusTabs/gzfk')"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img src="../../../assets/MoreMenuManager/folder.png" />
          <span class="custom-title">故障反馈</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push('/BusTabs/wtfk')"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img
            src="../../../assets/MoreMenuManager/folder.png"
            alt
          />
          <span class="custom-title">问题反馈</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push('/BusTabs/bysq')"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <img
            src="../../../assets/MoreMenuManager/folder.png"
            alt
          />
          <span class="custom-title">保养申请</span>
        </template>
      </van-cell>
    </div>
    <div v-else>
       <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:0}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">合同信息</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:1}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">整改报价</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:2}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">维保异常</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:3}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">配件更换</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:4}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">反馈内容</span>
        </template>
      </van-cell>
      <van-cell
        value
        is-link
        @click="$router.push({path:`/maintainceInfo`,query:{active:5}})"
      >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="wbxx-item">
            <img
              src="../../../assets/home/info.png"
              alt
            />
          </div>
          <span class="custom-title">日常巡检</span>
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
}
</script>
 
<style lang="less" scoped>
.contentsB {
  height: 100vh;
  background-color: rgb(245, 245, 245);
  img {
    width: 40px;
    vertical-align: middle;
    padding-right: 5px;
  }
  .custom-title {
    vertical-align: middle;
    padding-left: 10px;
  }
  .van-cell__title {
    display: flex;
    align-items: center;
  }
}
.headb {
  background: url('../../../assets/MoreMenuManager/personalCenterImg.jpg')
    no-repeat;
  background-size: 100% 100%;
  height: 50px;
  div {
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
  }
}
.wbxx-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #00a9ff;
  border-radius: 23px;
  img {
    width: 20px;
    padding: 0;
  }
}

.van-cell__right-icon {
  padding-top: 9px;
}
.van-cell__title {
  text-align: left;
  padding-left: 10px;
}
</style>
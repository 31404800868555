<template>
  <div class="map">
    <van-icon name="location" color="#3d60ef"></van-icon>
    <span>{{this.address}}</span>
  </div>
</template>
<script>
export default {
  name: "MapInfoWindow",
  data () {
    return {}
  },
  props: ['address']
}
</script>
<style lang="less" scoped>
.map {
  padding-top: 8px;
}
</style>